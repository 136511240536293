<template>
  <div class="landing-template page-wrapper">
    <router-view></router-view>
    <OrganismMenuRight :phone-header="phoneNumberHeader" />
    <OrganismSidebarCampaign />
    <OrganismMenuBar>
      <div class="hidden-xs">
        <a class="sidebar-link" v-for="(section, i) in sections" v-if="section.enabled && section.menuLabel" :href="`#${section.hashAnchor}`" :key="i">{{ section.menuLabel }}</a>
      </div>
    </OrganismMenuBar>
    <OrganismSidebar>
      <div class="menu">
        <div>
          <a class="sidebar-link" v-for="(section, i) in sections" v-if="section.enabled && section.menuLabel" :href="`#${section.hashAnchor}`" :key="i">{{ section.menuLabel }}</a>
        </div>
        <OrganismProjectLinks />
      </div>
      <div class="social-links">
        <a :href="instagramUrl"><AtomIcon icon="instagram" :size="24" color="#000"/></a>
        <a :href="linkedinUrl"><AtomIcon icon="linkedin-squared" :size="24" color="#000"/></a>
        <a :href="facebookUrl"><AtomIcon icon="facebook-squared" :size="24" color="#000"/></a>
      </div>
    </OrganismSidebar>
    <a class="footer-bright" href="https://brightspaces.tech/" target="_blank">Created by ©BrightSpaces</a>
  </div>
</template>

<script>
import {baseConstants} from "../store/modules";
import AtomIcon from "../components/atoms/common/AtomIcon";
import OrganismMenuRight from "@/components/organisms/OrganismMenuRight";
import OrganismSidebarCampaign from "@/components/organisms/OrganismSidebarCampaign";
import OrganismMenuBar from "@/components/organisms/OrganismMenuBar";
import OrganismSidebar from "@/components/organisms/OrganismSidebar";
import OrganismProjectLinks from "../components/organisms/OrganismProjectsLinks";

export default {
  name: "LandingTemplate",
  components: {
    OrganismProjectLinks,
    OrganismSidebar,
    OrganismMenuBar,
    OrganismSidebarCampaign,
    OrganismMenuRight,
    AtomIcon
  },
  computed: {
    sections() {
      return this.$route.meta.page.sections;
    },
    generalConfig() {
      return this.$store.state.base.meta.general
    },
    phoneNumberHeader() {
      if (!this.generalConfig.phoneNumberHeader) return '';
      return this.generalConfig.phoneNumberHeader;
    },
    instagramUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.instagramUrl;
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.linkedinUrl;
    },
    facebookUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.facebookUrl;
    },
    staticPages() {
      return this.generalConfig.staticPages || [];
    },
    portfolioPageDisabled() {
      return this.generalConfig.portfolioPageDisabled || false;
    },
  },

  mounted() {
    const self = this;
    document.querySelectorAll('a.sidebar-link[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', self.smoothScroll);
    });
    if (this.$route.hash !== '') {
      document.querySelector(this.$route.hash).scrollIntoView({
        behavior: 'smooth'
      });
    }
  },
  beforeDestroy() {
    const self = this;
    document.querySelectorAll('a.sidebar-link[href^="#"]').forEach(anchor => {
      anchor.removeEventListener('click', self.smoothScroll);
    });
  },
  methods: {
    smoothScroll(e) {
      e.preventDefault();
      document.querySelector(e.target.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.landing-template {
  display: block;
  position: relative;
  .project-scroll {
    .row-project {
      margin-bottom: 0.125rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 2px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .footer-bright {
    position: fixed;
    bottom: 0;
    left: 0;
    font-weight: $regular;
    padding: 0.313rem 1.25rem;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: $white;
    background: $blackOpacity06;
    z-index: 999;
  }
}
</style>